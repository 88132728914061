var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('p',{staticClass:"my-2",staticStyle:{"font-size":"28px","font-weight":"700"}},[_vm._v(" "+_vm._s(_vm.addition_set.name)+" ")])]),_c('div',{staticClass:"col-6 text-right"},[_c('b-button',{attrs:{"variant":"outline-primary text-primary"},on:{"click":function($event){_vm.edit_modal=true}}},[_c('UilEditAlt'),_vm._v(" Edit ")],1)],1),_c('div',{staticClass:"col-12 py-0 my-0 d-flex"},[_c('p',[_vm._v("Last Updated")]),_vm._v("    "),_c('p',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.formatDatetime(_vm.addition_set.updated_at))+" ")])])]),_c('b-tabs',[_c('b-tab',{attrs:{"active":"","title":"Spec"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-end"},[_c('b-form-input',{staticClass:"mt-1 mr-1",staticStyle:{"width":"350px"},attrs:{"placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-data-table',{staticClass:"elevation-1 m-2",attrs:{"headers":_vm.headers,"items":_vm.addition_set.addition_items,"sort-by":"name","items-per-page":25,"search":_vm.search},scopedSlots:_vm._u([_vm._l((_vm.filters),function(col,i){return {key:("header." + i),fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_c('div',{key:i,staticStyle:{"display":"inline-block","padding":"16px 0"}},[_vm._v(" "+_vm._s(header.text)+" ")]),_c('div',{key:'filter' + i,staticStyle:{"float":"right","margin-top":"8px"},attrs:{"id":"attachId"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":400,"min-width":300,"bottom":"","right":"","attach":"#attachId"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{style:(_vm.activeFilters[header.value] &&
                                  _vm.activeFilters[header.value].length <
                                  _vm.filters[header.value].length
                                  ? 'color:black'
                                  : 'color:#dedede'),attrs:{"small":""}},[_vm._v(" mdi-filter-variant ")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pa-0"},[_c('v-list-item-group',[_vm._l((_vm.filters[header.value]),function(item){return [_c('v-list-item',{key:("" + item),attrs:{"value":item,"ripple":false}},[[_c('v-list-item-action',[_c('b-form-checkbox',{attrs:{"value":item},model:{value:(_vm.activeFilters[header.value]),callback:function ($$v) {_vm.$set(_vm.activeFilters, header.value, $$v)},expression:"activeFilters[header.value]"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-weight":"normal","text-transform":"none"}},[_vm._v(_vm._s(item)+" ")])],1)]],2)]})],2)],1),_c('v-row',{staticStyle:{"background-color":"#fff"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('b-button',{staticStyle:{"border-color":"transparent","color":"rgba(92, 177, 231, 1)"},attrs:{"block":"","variant":"outline-light"},on:{"click":function($event){return _vm.toggleAll(header.value)}}},[_vm._v("Select all")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('b-button',{staticStyle:{"border-color":"transparent","color":"rgba(92, 177, 231, 1)"},attrs:{"block":"","variant":"outline-light"},on:{"click":function($event){return _vm.clearAll(header.value)}}},[_vm._v("Deselect all")])],1)],1)],1)],1)])]}}}),{key:"no-data",fn:function(){return [_vm._v(" There are not additions sets item to show ")]},proxy:true},{key:"item.total_price",fn:function(ref){
                                  var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.total_price).toLocaleString("en-CA", { minimumFractionDigits: 2, style: "currency", currency: "CAD", }))+" ")])]}},{key:"item.total_cost",fn:function(ref){
                                  var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.total_cost).toLocaleString("en-CA", { minimumFractionDigits: 2, style: "currency", currency: "CAD", }))+" ")])]}},{key:"item.is_base_spec",fn:function(ref){
                                  var item = ref.item;
return [(item.is_base_spec)?_c('div',[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"CheckIcon","size":"16"}})],1):_c('div',[_c('feather-icon',{staticClass:"text-body",attrs:{"icon":"XIcon","size":"16"}})],1)]}}],null,true)})],1)])])])],1),_c('b-modal',{attrs:{"hide-footer":true,"no-close-on-backdrop":true,"title":"Update Addition Set","size":"lg","centered":""},on:{"close":_vm.getCatalogue},model:{value:(_vm.edit_modal),callback:function ($$v) {_vm.edit_modal=$$v},expression:"edit_modal"}},[_c('EditFormCatalogue',{attrs:{"addition_set":_vm.addition_set},on:{"save":_vm.updateSpecSet}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }